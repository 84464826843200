<template>
  <div>
    <b-form-select
      :value="value"
      :options="options"
      size="sm"
      @input="onInput"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  name: 'AccountSelect',
  components: {},
  props: {
    value: {
      type: Number,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    options() {
      return this.accounts.map((account) => ({
        value: account.id,
        text: account.display_name,
      }));
    },
  },
  data() {
    return {
      loadingCount: 0,
      accounts: [],
    };
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    fetchAccounts() {
      this.loadingCount++;
      this.$http
        .get('/accounts')
        .then((res) => {
          this.accounts = res.body;
          this.accounts.unshift({ id: null, display_name: '' });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchAccounts();
  },
};
</script>

<style lang="scss" scoped>
</style>
